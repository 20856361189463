@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    overflow-y: overlay;
    height: 100%;
}

@font-face {
    font-family: 'White Rabbit Regular';
    font-style: normal;
    font-weight: normal;
    src: local('White Rabbit Regular'), url('assets/WHITRABT.woff') format('woff');
    font-display: swap
}